import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const CustomerListContainer = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
`;

const CustomerCard = styled.div`
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const CustomerName = styled.h2`
  margin: 0;
  color: #333;
`;

const CustomerDetails = styled.p`
  margin: 5px 0;
  color: #555;
`;

const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [error, setError] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const fetchToken = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        setIsAuthenticated(true);
        fetchCustomers(token);
      } else {
        setError('User not authenticated.');
      }
    };

    const fetchCustomers = async (token) => {
      try {
        const response = await axios.get('https://cosylabdesigns.com/api/customers/', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setCustomers(response.data);
      } catch (error) {
        console.error('Error fetching customers:', error);
        setError('Failed to fetch customers.');
      }
    };

    fetchToken();
  }, []);

  return (
    <CustomerListContainer>
      <Title>Customer Messages</Title>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {customers.map((customer) => (
        <CustomerCard key={customer.id}>
          <CustomerName>{customer.name}</CustomerName>
          <CustomerDetails>Email: {customer.email}</CustomerDetails>
          <CustomerDetails>Message: {customer.message}</CustomerDetails>
        </CustomerCard>
      ))}
    </CustomerListContainer>
  );
};

export default CustomerList;
