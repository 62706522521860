// Footer.js
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';


const FooterContainer = styled.footer`
  background-color: #f0f0f0; /* Match this to your design */
  padding: 20px;
  text-align: center;
  border-top: 1px solid #ccc;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const FooterLinks = styled.div`
  margin: 10px 0;
`;

const FooterLink = styled.a`
  color: #333;
  margin: 0 10px;
  text-decoration: none;
  font-size: 18px;

  &:hover {
    color: #25D366; /* WhatsApp green color, or another color matching your theme */
  }
`;


const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterLinks>
                <Link to="/products">蠟燭工作坊 </Link>
                <Link to="/plants">植物工作坊 </Link>
                <Link to="/about">關於我們</Link>
        </FooterLinks>
        <p>© 2024 CLAB. All rights reserved.</p>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
