import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Showcase from './ShowCase';
import { FaWhatsapp, FaInstagram } from 'react-icons/fa';
import EditProductForm from './EditProductForm';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
`;

const ProductsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
`;

const ProductContainer = styled.div`
  width: calc(30.33% - 20px);
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;

  img {
    max-width: 100%;
    height: auto;
  }

  h3 {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  @media (max-width: 1024px) {
    width: calc(45% - 20px);
  }

  @media (max-width: 768px) {
    width: calc(30% - 10px); /* Adjusted for two items per row */
  }
`;

const SocialMediaIcons = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  a {
    color: #25D366; /* WhatsApp green color */
    font-size: 30px;

    &:last-child {
      color: #E1306C; /* Instagram pink color */
    }

    &:hover {
      opacity: 0.8;
    }
  }
`;

const AddProductForm = ({ onAddProduct }) => {
  const [name, setName] = useState('');
  const [image, setImage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('image', image);

      await onAddProduct(formData);
    } catch (error) {
      console.error('Error adding product:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={name}
        onChange={handleNameChange}
        placeholder="Product Name"
        required
      />
      <input
        type="file"
        onChange={handleImageChange}
        accept="image/*"
        required
      />
      <button type="submit" disabled={isSubmitting}>
        {isSubmitting ? 'Adding...' : 'Add Product'}
      </button>
    </form>
  );
};

const getCSRFToken = () => {
  let csrfToken = null;
  const cookies = document.cookie.split(';');
  for (let cookie of cookies) {
    const [name, value] = cookie.trim().split('=');
    if (name === 'csrftoken') {
      csrfToken = value;
      break;
    }
  }
  return csrfToken;
};

const Products = ({ isAuthenticated, onLogout }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingProduct, setEditingProduct] = useState(null);
  const [brandDescription, setBrandDescription] = useState(`
    CLAB烘焙蠟燭證書課 | 多肉小盆栽蠟燭 | 手捏蠟燭
    💌CLAB證書課、各蠟燭單品課請經Instagram/Whataspp查詢及預約
    到校課程、社區計劃、外展活動、禮品訂製及各類合作歡迎聯絡
    🔖合作夥伴：小童群益會、寧波第二中學、慕光英文書院等
  `);
  const [editingDescription, setEditingDescription] = useState(false);
  const [newDescription, setNewDescription] = useState('');

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const headers = isAuthenticated ? { Authorization: `Bearer ${localStorage.getItem('token')}` } : {};
        const response = await fetch('https://cosylabdesigns.com/api/products/', {
          headers: headers,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        if (Array.isArray(data)) {
          setProducts(data);
        } else {
          throw new Error('Data is not an array');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchBrandDescription = async () => {
      try {
        const response = await fetch('https://cosylabdesigns.com/api/candle-descriptions/');
        if (!response.ok) {
          console.error('Failed to fetch brand description:', response.statusText);
          throw new Error('Failed to fetch brand description');
        }
        const data = await response.json();
        setBrandDescription(data.description);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchProducts();
    fetchBrandDescription();
  }, [isAuthenticated]);

  const handleAddProduct = async (formData) => {
    try {
      const headers = isAuthenticated ? { Authorization: `Bearer ${localStorage.getItem('token')}` } : {};
      const response = await fetch('https://cosylabdesigns.com/api/products/', {
        method: 'POST',
        headers: headers,
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to add product');
      }

      const newProduct = await response.json();
      setProducts([...products, newProduct]);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleEditProduct = async (productId, updatedProductData) => {
    try {
      const headers = isAuthenticated ? { Authorization: `Bearer ${localStorage.getItem('token')}` } : {};
      const response = await fetch(`https://cosylabdesigns.com/api/products/${productId}/`, {
        method: 'PUT',
        headers: headers,
        body: updatedProductData,
      });

      if (!response.ok) {
        throw new Error('Failed to edit product');
      }

      const updatedProduct = await response.json();
      setProducts(products.map((p) => (p.id === productId ? updatedProduct : p)));
      setEditingProduct(null);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      };

      const response = await fetch(`https://cosylabdesigns.com/api/products/${productId}/`, {
        method: 'DELETE',
        headers: headers,
      });

      if (!response.ok) {
        throw new Error('Failed to delete product');
      }

      setProducts(products.filter((p) => p.id !== productId));
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSubmitBrandDescription = async () => {
    try {
      const csrfToken = getCSRFToken();
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
        'X-CSRFToken': csrfToken,
      };
      const response = await fetch('https://cosylabdesigns.com/api/candle-descriptions/update_description/', {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({ description: newDescription }),
      });

      if (!response.ok) {
        throw new Error('Failed to update brand description');
      }

      const data = await response.json();
      setBrandDescription(data.description);
      setEditingDescription(false);
    } catch (error) {
      setError(error.message);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Container>
      {isAuthenticated && <button onClick={onLogout}>Logout</button>}
      <Showcase />
      <p>{brandDescription}</p>
      {isAuthenticated && (
        <>
          {editingDescription ? (
            <div>
              <textarea
                value={newDescription}
                onChange={(e) => setNewDescription(e.target.value)}
              />
              <button onClick={handleSubmitBrandDescription}>Submit</button>
            </div>
          ) : (
            <button onClick={() => setEditingDescription(true)}>Edit Brand Description</button>
          )}
        </>
      )}
      <ProductsContainer>
        {products.map((product) => (
          <ProductContainer key={product.id}>
            <h3>{product.name}</h3>
            <img src={product.image} alt={product.name} />
            {isAuthenticated && (
              <>
                <button onClick={() => handleDeleteProduct(product.id)}>Delete</button>
                <button onClick={() => setEditingProduct(product)}>Edit</button>
              </>
            )}
          </ProductContainer>
        ))}
      </ProductsContainer>
      {isAuthenticated && (
        <>
          <h3>Add a New Product</h3>
          <AddProductForm onAddProduct={handleAddProduct} />
          {editingProduct && (
            <EditProductForm
              product={editingProduct}
              onSave={(updatedProductData) => handleEditProduct(editingProduct.id, updatedProductData)}
              onCancel={() => setEditingProduct(null)}
            />
          )}
        </>
      )}
      <SocialMediaIcons>
        <a href="https://wa.me/85293806161" target="_blank" rel="noopener noreferrer">
          <FaWhatsapp />
        </a>
        <a href="https://www.instagram.com/cosylabdesigns" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </a>
      </SocialMediaIcons>
    </Container>
  );
};

export default Products;

