// src/googleAnalytics.js

export const initGA = () => {
    // Load Google Analytics script
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-CGCRC2CBW2';
    script.async = true;
    document.head.appendChild(script);
  
    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-CGCRC2CBW2');
  };
  