import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Products from './components/Products';
import Login from './components/Login';
import Navbar from './components/NavBar';
import Showcase from './components/ShowCase';
import AboutUs from './components/AboutUs';
import CustomerList from './components/CustomerList';
import Plants from './components/Plant';
import Footer from './components/Footer';

const App = () => {
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
            setToken(storedToken);
            setIsAuthenticated(true);
        } else {
            setToken(null);
            setIsAuthenticated(false);
        }
    }, []);

    const handleLogin = (newToken) => {
        setToken(newToken);
        setIsAuthenticated(true);
        localStorage.setItem('token', newToken);
    };

    const handleLogout = () => {
        setToken(null);
        setIsAuthenticated(false);
        localStorage.removeItem('token');
    };

    return (
        <Router>
            <Helmet>
                <title>CosyLab - Home</title>
                <meta name="description" content="Welcome to CosyLab, your go-to place for workshops and more." />
                <meta name="keywords" content="CosyLab, workshops, candles, plants" />
                <meta property="og:title" content="CosyLab - Home" />
                <meta property="og:description" content="Welcome to CosyLab, your go-to place for workshops and more." />
                <meta property="og:url" content="https://cosylabdesigns.com/" />
                <meta property="og:type" content="website" />
                <script type="application/ld+json">
                    {`
                        {
                            "@context": "http://schema.org",
                            "@type": "Organization",
                            "name": "CosyLab",
                            "url": "https://cosylabdesigns.com/",
                            "logo": "https://cosylabdesigns.com/logo.png",
                            "sameAs": [
                                "https://www.facebook.com/CosyLab.HK",
                                "https://instagram.com/cosylab.hk",
                            ]
                        }
                    `}
                </script>
            </Helmet>
            <Navbar isAuthenticated={isAuthenticated} />
            <Routes>
                <Route path="/" element={<Products />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/showcase" element={<Showcase />} />
                <Route path="/customers" element={<CustomerList />} />
                <Route path="/login" element={<Login setToken={handleLogin} />} />
                <Route path="/products" element={<Products token={token} isAuthenticated={isAuthenticated} onLogout={handleLogout} />} />
                <Route path="/plants" element={<Plants token={token} isAuthenticated={isAuthenticated} onLogout={handleLogout} />} />
            </Routes>
            <Footer />
        </Router>
    );
};

export default App;
