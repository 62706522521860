import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Nav = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #333;
    color: white;
    max-width: var(--page-width);
    margin: 0 auto;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const NavLinks = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;

    a {
        color: white;
        text-decoration: none;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
        gap: 0.5rem;
        padding: 0.5rem;
    }
`;

const PortfolioLink = styled.a`
    color: white;
    text-decoration: none;

    @media (max-width: 768px) {
        margin-bottom: 0.5rem;
    }
`;

const Hamburger = styled.div`
    display: none;
    flex-direction: column;
    cursor: pointer;

    @media (max-width: 768px) {
        display: flex;
    }

    div {
        width: 25px;
        height: 3px;
        background-color: white;
        margin: 4px 0;
    }
`;

const Navbar = ({ isAuthenticated, token }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [cacheBuster, setCacheBuster] = useState(Date.now());

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        
        // Check if the file is a PDF
        if (file && file.type === 'application/pdf') {
            const formData = new FormData();
            formData.append('portfolio', file);
            
            try {
                const headers = isAuthenticated ? { Authorization: `Bearer ${localStorage.getItem('token')}` } : {};
                const response = await fetch('https://cosylabdesigns.com/api/portfolio/upload/', {
                    method: 'POST',
                    headers: headers,
                    body: formData,
                });
        
                if (!response.ok) {
                    console.log('headers:', headers);  
                    throw new Error('Failed to upload portfolio');
                }
                setCacheBuster(Date.now());
                alert('Portfolio uploaded successfully');
            } catch (error) {
                console.error('Error uploading portfolio:', error);
                alert('Error uploading portfolio');
            }
        } else {
            alert('Please upload a PDF file.');
        }
    };
    

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Nav>
            <h1>CosyLab</h1>
            <Hamburger onClick={toggleMenu}>
                <div />
                <div />
                <div />
            </Hamburger>
            <NavLinks $isOpen={isOpen}>
                <Link to="/products">蠟燭工作坊</Link>
                <Link to="/plants">植物工作坊</Link>
                <Link to="/about">關於我們</Link>
                
                <PortfolioLink href={`https://cosylabdesigns.com/portfolios/portfolio.pdf?cacheBuster=${cacheBuster}`} target="_blank" rel="noopener noreferrer">
                    Portfolio
                </PortfolioLink>
                {isAuthenticated && (
                    <input type="file" onChange={handleFileChange} />
                )}
            </NavLinks>
        </Nav>
    );
};

export default Navbar;
