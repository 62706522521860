import React, { useState, useEffect } from 'react';

const EditProductForm = ({ product, onEditProduct, onCancelEdit }) => {
  const [name, setName] = useState(product.name);
  const [image, setImage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setName(product.name);
  }, [product.name]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type !== 'image/jpeg') {
      alert('Please upload a JPEG image.');
      setImage(null); // Clear the selected file
      return;
    }
    setImage(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const updatedProductData = new FormData();
      updatedProductData.append('name', name);
      if (image) {
        updatedProductData.append('image', image);
      }

      await onEditProduct(product.id, updatedProductData);
    } catch (error) {
      console.error('Error editing product:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={name}
        onChange={handleNameChange}
        placeholder="Product Name"
        required
      />
      <input
        type="file"
        onChange={handleImageChange}
        accept="image/*"
      />
      <button type="submit" disabled={isSubmitting}>
        {isSubmitting ? 'Saving...' : 'Save'}
      </button>
      <button type="button" onClick={onCancelEdit}>Cancel</button>
    </form>
  );
};

export default EditProductForm;
