// src/components/ContactUs.js

import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;

  input, textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }

  button {
    padding: 10px;
    border: none;
    background-color: #333;
    color: white;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;

    &:hover {
      background-color: #555;
    }
  }

  @media (max-width: 768px) {
    input, textarea, button {
      font-size: 0.875rem;
    }
  }
`;

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://cosylabdesigns.com/api/contact/', formData);
      if (response.status === 201) {
        alert('Message sent successfully');
        setFormData({ name: '', email: '', message: '' });
      }
    } catch (error) {
      console.error('There was an error sending the message!', error);
    }
  };

  return (
    <ContactForm onSubmit={handleSubmit}>
      <input 
        type="text" 
        name="name" 
        placeholder="Your Name" 
        value={formData.name} 
        onChange={handleChange} 
        required 
      />
      <input 
        type="email" 
        name="email" 
        placeholder="Your Email" 
        value={formData.email} 
        onChange={handleChange} 
        required 
      />
      <textarea 
        name="message" 
        placeholder="Your Message" 
        value={formData.message} 
        onChange={handleChange} 
        required 
      />
      <button type="submit">Send</button>
    </ContactForm>
  );
};

export default ContactUs;
