import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const SlideshowContainer = styled.div`
  width: 100%;
  max-width: 768px; /* Adjust this as needed */
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const PlantShowCase = () => {
  const displayImages = [
    'plantsClassImage/plantClassIimage1.jpeg',
    'plantsClassImage/plantClassIimage2.jpeg',
    'plantsClassImage/plantClassIimage3.jpeg',
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % displayImages.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [displayImages.length]);

  const currentImage = displayImages[currentIndex];

  return (
    <SlideshowContainer>
      <img src={currentImage} alt="Slideshow" />
    </SlideshowContainer>
  );
};

export default PlantShowCase;
