import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaWhatsapp, FaInstagram } from 'react-icons/fa';
import EditPlantForm from './EditPlantForm'; // Ensure you create a similar component for editing plants
import AddPlantForm from './AddPlantForm'; // Ensure you create a similar component for adding plants
import PlantShowCase from './PlantShowCase'; // Ensure you create a similar component for showcasing plants

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
`;

const PlantsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
`;

const PlantContainer = styled.div`
  width: calc(30.33% - 20px);
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;

  img {
    max-width: 100%;
    height: auto;
  }

  h3 {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  @media (max-width: 1024px) {
    width: calc(45% - 20px);
  }

  @media (max-width: 768px) {
    width: calc(30% - 10px); /* Adjusted for two items per row */
  }
`;

const SocialMediaIcons = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  a {
    color: #25D366; /* WhatsApp green color */
    font-size: 30px;

    &:last-child {
      color: #E1306C; /* Instagram pink color */
    }

    &:hover {
      opacity: 0.8;
    }
  }
`;

const Plants = ({ isAuthenticated, onLogout }) => {
  const [plants, setPlants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingPlant, setEditingPlant] = useState(null);

  const [brandDescription, setBrandDescription] = useState(`
    CLAB多肉植物系列 | 手工植物盆栽 | 多肉植物訂製
    💌CLAB植物系列、訂製服務請經Instagram/Whatsapp查詢及預約
    到校課程、社區計劃、外展活動、禮品訂製及各類合作歡迎聯絡
    🔖合作夥伴：小童群益會、寧波第二中學、慕光英文書院等
  `);

  const [editingDescription, setEditingDescription] = useState(false);
  const [newDescription, setNewDescription] = useState('');

  useEffect(() => {
    const fetchPlants = async () => {
      try {
        const headers = isAuthenticated ? { Authorization: `Bearer ${localStorage.getItem('token')}` } : {};

        const response = await fetch('https://www.cosylabdesigns.com/api/plants/', {
          headers: headers,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        if (Array.isArray(data)) {
          setPlants(data);
        } else {
          throw new Error('Data is not an array');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchBrandDescription = async () => {
      try {
        const response = await fetch('https://www.cosylabdesigns.com/api/plants-descriptions/');
        if (!response.ok) {
          console.error('Failed to fetch brand description:', response.statusText);
          throw new Error('Failed to fetch brand description');
        }
    
        const data = await response.json();
        setBrandDescription(data.description);
      } catch (error) {
        setError(error.message);
      }
    }

    fetchBrandDescription();
    fetchPlants();
  }, [isAuthenticated]);

  const handleLogout = () => {
    onLogout();
  };

  const getCSRFToken = () => {
    let csrfToken = null;
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      const [name, value] = cookie.trim().split('=');
      if (name === 'csrftoken') {
        csrfToken = value;
        break;
      }
    }
    return csrfToken;
  };

  const handleAddPlant = async (formData) => {
    try {
      const headers = isAuthenticated ? { Authorization: `Bearer ${localStorage.getItem('token')}` } : {};

      const response = await fetch('https://www.cosylabdesigns.com/api/plants/', {
        method: 'POST',
        headers: headers,
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to add plant');
      }

      const newPlant = await response.json();
      setPlants([...plants, newPlant]);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleEditPlant = async (plantId, updatedPlantData) => {
    try {
      const headers = isAuthenticated ? { Authorization: `Bearer ${localStorage.getItem('token')}` } : {};
      const response = await fetch(`https://www.cosylabdesigns.com/api/plants/${plantId}/`, {
        method: 'PUT',
        headers: headers,
        body: updatedPlantData,
      });

      if (!response.ok) {
        throw new Error('Failed to edit plant');
      }

      const updatedPlant = await response.json();
      setPlants(plants.map((p) => (p.id === plantId ? updatedPlant : p)));
      setEditingPlant(null);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDeletePlant = async (plantId) => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      };

      const response = await fetch(`https://www.cosylabdesigns.com/api/plants/${plantId}/`, {
        method: 'DELETE',
        headers: headers,
      });

      if (!response.ok) {
        throw new Error('Failed to delete plant');
      }

      setPlants(plants.filter((p) => p.id !== plantId));
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSubmitBrandDescription = async () => {
    
    try {
      const csrfToken = getCSRFToken();
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'X-CSRFToken': csrfToken,
      };

      const response = await fetch('https://www.cosylabdesigns.com/api/plants-descriptions/update_description/', {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({ description: newDescription }),
      });

      if (!response.ok) {
        throw new Error('Failed to update brand description');
      }

      const data = await response.json();
      setBrandDescription(data.description);
      setEditingDescription(false);
    } catch (error) {
      setError(error.message);
    }
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Container>
     
      {isAuthenticated && <button onClick={handleLogout}>Logout</button>}
      <PlantShowCase />
      <p>{brandDescription}</p>
      {isAuthenticated && (
        <>
          {editingDescription ? (
            <>
              <textarea
                value={newDescription}
                onChange={(e) => setNewDescription(e.target.value)}
                rows={4}
                cols={50}
              />
              <button onClick={handleSubmitBrandDescription}>Save Description</button>
              <button onClick={() => setEditingDescription(false)}>Cancel</button>
            </>
          ) : (
            <>
              <div>
                <button onClick={() => setEditingDescription(true)}>Edit Description</button>
              </div>
            </>
          )}
        </>
      )}
      {isAuthenticated && <AddPlantForm onAddPlant={handleAddPlant} />}
      <PlantsContainer>
        {plants.map((plant) => (
          <PlantContainer key={plant.id}>
            {editingPlant === plant.id ? (
              <EditPlantForm
                plant={plant}
                onEditPlant={handleEditPlant}
                onCancelEdit={() => setEditingPlant(null)}
              />
            ) : (
              <>
                <img src={plant.image} alt={plant.name} />
                <h3>{plant.name}</h3>
                {isAuthenticated && (
                  <>
                    <button onClick={() => setEditingPlant(plant.id)}>Edit</button>
                    <button onClick={() => handleDeletePlant(plant.id)}>Delete</button>
                  </>
                )}
              </>
            )}
          </PlantContainer>
        ))}
      </PlantsContainer>
      <SocialMediaIcons>
        <a href="https://wa.me/97546772" target="_blank" rel="noopener noreferrer">
          <FaWhatsapp />
        </a>
        <a href="https://www.instagram.com/cosylab.craft" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </a>
      </SocialMediaIcons>
    </Container>
  );
};

export default Plants;
