// AddPlantForm.js
import React, { useState } from 'react';

const AddPlantForm = ({ onAddPlant }) => {
  const [name, setName] = useState('');
  const [image, setImage] = useState(null);

  const handleNameChange = (e) => setName(e.target.value);
  const handleImageChange = (e) => setImage(e.target.files[0]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    if (image) formData.append('image', image);
    await onAddPlant(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="text" value={name} onChange={handleNameChange} placeholder="Plant Name" required />
      <input type="file" onChange={handleImageChange} accept="image/*" />
      <button type="submit">Add Plant</button>
    </form>
  );
};

export default AddPlantForm;
