import React from 'react';
import styled from 'styled-components';
import { GoogleMap, LoadScript,Marker } from '@react-google-maps/api';
import ContactUs from './ContactUs';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

const Heading = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

const MapContainer = styled.div`
  height: 400px;
  width: 100%;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    height: 300px;
  }
`;

const AboutUs = () => {
  React.useEffect(() => {
    console.log('AboutUs component mounted');
    return () => {
      console.log('AboutUs component unmounted');
    };
  }, []);

  return (
    <Container>
      <Section>
        <Heading>About Us</Heading>
        <p>
          Welcome to CLAB! We offer a variety of candle-making classes and products.
          Our courses include 烘焙蠟燭證書課, 多肉小盆栽蠟燭, and 手捏蠟燭. We also provide
          on-campus courses, community projects, outreach activities, and customized gift services.
        </p>
      </Section>

      <Section>
        <Heading>Our Location</Heading>
        <MapContainer>
          <LoadScript 
            googleMapsApiKey="AIzaSyCFkZNvytwh7YZGGXgP-u0Ccw6FbYA8oyE"
            loadingElement={<div>Loading Map...</div>}
          >
            <GoogleMap
              mapContainerStyle={{ height: '100%', width: '100%' }}
              center={{ lat: 22.337440, lng: 114.195600 }}
              zoom={15}
              onLoad={() => console.log('GoogleMap loaded')}
              onUnmount={() => console.log('GoogleMap unmounted')}
            >
              {/* Use Marker for now; migrate to AdvancedMarkerElement in the future */}
              <Marker position={{ lat: 22.337230385132347, lng: 114.2003406576707 }} />
            </GoogleMap>
          </LoadScript>
        </MapContainer>
      </Section>

      <Section>
        <Heading>Contact Us</Heading>
        <ContactUs />
      </Section>
    </Container>
  );
};

export default AboutUs;
